<template>
  <div class="case-detail">
    <Top title="案例详情" />
    <div class="pt44">
      <div class="main-content bg-block mt8">
        <div class="content-block">
          <div class="user-info flex-between border-bottom pb10">
            <div class="info-left flex-start">
              <div>
                <span>{{ detail.title }}</span>
                <p class="mt5">
                  <span class="bg-grey-color tag fz10">{{
                    detail.orderClassficationName
                  }}</span>
                  <span class="bg-grey-color tag fz10">{{
                    detail.matterClassficationName
                  }}</span>
                  <span class="ft-red fz10 ml5">{{
                    detail.matterPropertyName
                  }}</span>
                </p>
              </div>
            </div>
            <div class="info-right">
              <span
                v-if="detail.isPractice"
                class="iconfont icon-hps-appzuijiashijian fz20 ft-org"
              ></span>
              <div class="ft-grey fz12">
                {{ formatDate(detail.reportTime) }}
              </div>
            </div>
          </div>
          <div class="content-detail border-bottom pb15">
            <div class="user-info flex-between mt15">
              <div class="info-left flex-start">
                <a-avatar
                  :size="40"
                  icon="user"
                  class="mr10"
                  :src="detail.reporterAvatar"
                />
                <div>
                  <span>{{ detail.nextUserName }} 提报人</span>
                  <p class="mt5">
                    <span class="fz10 ft-grey">{{
                      detail.orderCreateDept
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="info-right">
                <img class="icon" src="@/assets/bef.png" />
              </div>
            </div>
            <div class="order-info">
              <div class="mt10">事项描述</div>
              <p class="fz12 ft-grey mt5">{{ detail.des }}</p>
              <div class="mt10">建议及期望</div>
              <p class="fz12 ft-grey mt5">{{ detail.advice }}</p>
              <vuer :images="imagesBefore" v-if="detail.pictureUrl">
                <img v-for="src in imagesBefore" :src="src" :key="src" />
              </vuer>
            </div>
          </div>
          <div class="content-detail" v-if="feReceipt.createName">
            <div class="user-info flex-between mt15">
              <div class="info-left flex-start">
                <a-avatar
                  :size="40"
                  icon="user"
                  class="mr10"
                  :src="feReceipt.createUserAvatar"
                />
                <div>
                  <span>{{ feReceipt.createName }} 处理人</span>
                  <p class="mt5">
                    <span class="fz10 ft-grey">{{
                      detail.orderCreateDept
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="info-right">
                <img class="icon" src="@/assets/aft.png" />
              </div>
            </div>
            <div class="order-info">
              <div class="mt10">处理描述</div>
              <p class="fz12 ft-grey mt5">{{ feReceipt.receiptDesc }}</p>
              <vuer :images="imagesAfter" v-if="feReceipt.receiptPictureUrl">
                <img v-for="src in imagesAfter" :src="src" :key="src" />
              </vuer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Top from "@/components/top/top";
import { getDetail } from "@/utils/api";
import { Dropdown, Icon, Menu, Avatar } from "ant-design-vue";
import "viewerjs/dist/viewer.css";
import Vuer from "v-viewer";
import Vue from "vue";
Vue.use(Vuer, { name: "vuer" });
Vuer.setDefaults({ movable: false, toolbar: false });
export default {
  components: {
    Top,
    AAvatar: Avatar,
  },
  data() {
    return {
      id: this.$route.query.id,
      detail: {
        id: "",
        department: "创新发展部",
        nextUserName: "喜喜",
        nextUserAvatar: "",
        orderClassficationName: "问题反馈",
        matterClassficationName: "安全",
        matterPropertyName: "一般",
        statusName: "问题反馈",
        title: "为方便拾取螺栓，自制吸铁棒",
        des: "OE047位在预带增压器回油管螺栓时，有时螺栓会掉落在地面上， 由于该工位空间狭窄，所以每次掉落...",
        reportTime: "2022/12/12 23:40:00",
      },
      feReceipt: {
        createName: "",
        createTime: "",
        createMobile: "",
        receiptDesc: "",
      },
      imagesBefore: [],
      imagesAfter: [],
    };
  },
  computed: {
    defaultImg() {
      return `this.src="${require("@/assets/img/default.png")}"`;
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      const { data } = await getDetail(this.id);
      this.detail = data;
      this.imagesBefore.push(data.pictureUrl);
      if (data.feReceipt) {
        this.feReceipt = data.feReceipt;
        this.imagesAfter.push(data.feReceipt.receiptPictureUrl);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.info-right {
  text-align: right;
}
.content-block {
  padding: 1rem 1.5rem;
}
.order-info {
  img {
    width: 10.4rem;
    height: 7.5rem;
    border-radius: 3px;
    display: block;
  }
}
.content-detail {
  .icon {
    width: 3.9rem;
    height: 3.4rem;
  }
}
</style>